import { Injectable } from '@angular/core';
import { ModalServiceService } from "./modal-service.service";
import { isNumber, isNull, isUndefined } from 'lodash';
import { iPopUpMessageComponent } from '../components/message-modal/message-modal.component';
import {ErrorReporterService} from "./error-reporter.service";
import {ErrorReportData} from "../../interfaces/errors/ErrorReportData.i";
import {HttpErrorResponse} from "@angular/common/http";
import {PortalConfigService} from "./portal-config.service";



@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
      private modalService: ModalServiceService,
      private errorReporterService: ErrorReporterService,
      private portalConfig: PortalConfigService,
  ) { }

  /**
   * @name analizeAndShow
   * @param error { HttpError | ApiError }
   *
   * @description
   * HttpError: Es un error general de HTTP (500, 403, etc)
   * ApiError: Errores provenientes de la API del SOAT.
   *
   * Analiza el error que se le manda por parámetro y dependiendo del tipo
   * y contenido muestra un modal de error.
   *
   * @returns Promise<HTMLIonModalElement>
   */
  public async analizeAndShow(error, title: string = 'Oops!', errorCode?: string): Promise<HTMLIonModalElement> {
    // this.checkAndParseErrorToReport(error).then();
    // defaults
    let errorModalOptions: iPopUpMessageComponent = {
      type: 'error',
      buttonMessage: 'Continuar',
      title,
      message: ''
    };

    // si hay número de estado es porque es un error no controlado.
    // si no hay MessageType también es un error no controlado.
    // si el MessageType es distinto de 1, es un error no controlado.
    if (isNumber(error.status) || isNull(error.MessageType) || isUndefined(error.MessageType) || error.MessageType !== '1') {
      errorModalOptions.message = 'Ha ocurrido un error inesperado, inténtelo nuevamente.'
      console.log(error);
    }
    else {
      errorModalOptions.message = error.Message;
    }

    if (errorCode) {
      errorModalOptions.message += ` (cod: ${errorCode})`;
    }

    const modal = await this.modalService.showErrorModal(errorModalOptions);
    return modal;
  }

  /**
   * @name showError
   *
   * @param message string
   * @param type string
   * @param title string
   *
   * @description
   * Muestra un modal de error customizable.
   *
   * @returns Promise<HTMLIonModalElement>
   */
  public async showError(message: string, type: 'error' | 'info' | 'warning', title?: string): Promise<HTMLIonModalElement> {
    const modal = await this.modalService.showErrorModal({
      buttonMessage: 'Continuar',
      message: message,
      title: title || 'Ha ocurrido un inconveniente',
      type
    });
    return modal;
  }

  private async checkAndParseErrorToReport(error: any): Promise<void> {
    let data: ErrorReportData = {
      RequestURL: null,
      NumberPlate: null,
      RequestBody: null,
      UserAgent: null,
      Details: null,
      EventSource: null,
      EventName: null,
      EventType: null,
      EntityId: null,
      EntityName: null,
      ErrorCode: null,
      ContactHash: null
    };
    data.UserAgent = window.navigator.userAgent;

    let allPortalConfig: ParsedConfig = null;
    const isPortalConfigured = this.portalConfig.isConfigured;

    if (isPortalConfigured) {
      allPortalConfig = this.portalConfig.wholeConfig;
      data.EntityName = allPortalConfig.EntityName || '';
      data.EntityId = allPortalConfig.EntityId || null;
      data.ContactHash = this.portalConfig.getHash() || '';
    }
    switch (true) {
      case error instanceof HttpErrorResponse:
        data.EventType = 'Error';
        data.EventName = 'HttpErrorResponse';
        data.Details = JSON.stringify(error) || '';
        data.EventSource = null;
        data.RequestBody = JSON.stringify(error.error) || '';
        data.RequestURL = error.url || '';
        break;

      case error.Success === false:
        data.EventType = 'HttpResponse';
        data.EventName = 'SuccessFalse';
        data.Details = JSON.stringify(error) || '';
        break;

      default:
        data.EventType = 'Error';
        data.EventName = 'Error';
        data.Details = JSON.stringify(error);
    }

    this.errorReporterService.report(data).then();
  }
}
